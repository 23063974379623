<template>
  <div>
    <div class="container-intermidiate">
      <div class="main-card" v-for="(qualitys, index) in qualityList" :key="index">
        <div class="radio-btn">
          <input
            :id="qualitys.title"
            name="radio-quality"
            type="radio"
            v-model="quality"
            :value="qualitys.title"
            class="radio-input"
          />
          <label :for="qualitys.title" class="radio-label"></label>
        </div>
        <div>
          <h4>{{ qualitys.title }}</h4>
          <p v-if="qualitys.currency && qualitys.amount" class="currency-tag">{{qualitys.currency+qualitys.amount}}</p>
          <p>{{ content }}</p>
          <!-- <div class="vlt-btn submit-btns">
        <button class="btn-login" @click="actClicked">{{ data }}</button>
          </div>-->
        </div>
      </div>
    </div>
    <div class="subs-btns">
      <button
        type="submit"
        class="btn-login btn-lg-primary inverted button-click-hover"
        @click="actQualityCB"
      >PURCHASE</button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import {currencyPass} from '@/mixins/dateSupport.js';

export default {
  props: ["cardContent","country"],
  data() {
    return {
      content: "to watch the content",
      qualityList: [],
      quality: "HD"
    };
  },
  watch:{
     cardContent(){
        this.createQualityList();
     },
     country(){
       this.createQualityList();
     } 
  }
  ,
  methods: {
    actQualityCB() {
      let param = {};
      param.data = "payment";
      param.content = this.cardContent;
      param.selectedtype = this.quality;
      param.frompage = "purchase";
      // console.log("param ", param)
      EventBus.$emit("checkDisplayPayment", param);
    },
    // actClicked() {
    //   EventBus.$emit("checkDisplayPayment", this.data);
    // },

    createQualityList() {
      if(this.cardContent && this.cardContent.price && Array.isArray(this.cardContent.price)){
        this.qualityList=this.cardContent.price.map(pricetype=>{
          return {
            title:pricetype.quality,
            amount:pricetype.amount?(pricetype.amount/100):0,
            currency:pricetype.currency==="INR"?"₹ ":"$ ",
            currencyvalue:pricetype.currency
          }
        }).filter((prices)=>currencyPass(prices.currencyvalue,this.country));
      }
      else {
        this.qualityList = [{ title: "HD" }, { title: "SD" }];
      }
      this.quality=this.qualityList[0]?.title;
    }
  },
  created() {
    // console.log("cardcontent ", this.cardContent)
    this.createQualityList();
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.container-intermidiate {
  width: 100%;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  .main-card {
    width: 45%;
    background: $clr-color-black-2 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $clr-color-black-3;
    border: 3px solid $clr-bg-gray-dark;
    border-radius: 12px;
    // text-align: center;
    padding: 3%;
    display: flex;
    // flex-flow: column wrap;
    // justify-content: center;
    align-items: center;
    .radio-btn {
      padding-right: 5%;
    }
    .radio-input {
      opacity: 0;
      position: absolute;
      &:checked {
        + label::before {
          border: 2px solid $theme-primary;
        }
        + label::after {
          width: 10px;
          height: 10px;
          left: 4px;
          top: 4px;
          border-radius: 50%;
          background-color: $theme-primary;
        }
        + label {
          opacity: 1;
          font-weight: bold;
          color: $theme-primary;
        }
      }
    }
    label {
      position: relative;
      padding-left: 25px;
      font-weight: 300;
       cursor: pointer;
      &::before,
      &::after {
        position: absolute;
        content: "";
      }
      &::before {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        left: 0;
        color: $theme-primary;
        top: 0;
        border: 2px solid $theme-primary;
      }
    }

    h4 {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      font-size:1.25rem;
      color: $font-clr-white;
      // margin-top: 13.5%;
    }
    p {
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      &.currency-tag{
        font-weight: $font-weight-semibold;
        font-size:1.25rem;
      }
    }
    .vlt-btn {
      width: 70%;
      margin-top: 30%;
      margin-bottom: 10%;
      .btn-login {
        font-size: 1rem;
      }
    }
  }
}
.subs-btns {
  text-align: center;
  margin-top: 5%;
  .btn-login {
    width: 30%;
  }
}


@include breakpoint(max600) {
.subs-btns, .btn-login{
  font-size: 8px !important;
}
}
</style>